<template>
    <v-sheet outlined>
      <v-toolbar color="primary" dark dense>
        <v-row>
          <v-col cols="12">
            <span class="font-weight-bold">Control Panel</span>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-container class="mt-5" fluid>
        <v-row>
          <v-col cols="12" md="9">
            <v-select
              dense
              :items="connectors"
              label="Connector"
              item-text="description"
              item-value="pk"
              v-model.number="selectedConnector"
              return-object
              clearable
              class="caption"
            />
          </v-col>
          <v-col cols="12" md="3">
            <connector-pin-modal
              :connector="selectedConnector"
              :userId="userId"
              :fixtureDesignId="fixtureDesignId"
              :projectId="projectId"
              :tpcbConfigId="tpcbConfigId"
              @saveData="saveHandler"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
</template>
<script>
export default {
  props: {
    connectors: {
      type: Array,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },
    fixtureDesignId: {
      type: Number,
      default: null,
    },
    projectId: {
      type: String,
      default: null,
    },
    tpcbConfigId: {
      type: Number,
      default: null,
    },
  },
  components: {
    ConnectorPinModal: () => import("./ConnectorPinModal.vue"),
  },
  data() {
    return {
      selectedConnector: null,
    };
  },
  methods: {
    saveHandler(payload) {
      this.$emit("saveData", payload);
    },
  },
};
</script>
